// npm
import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface StyleProps {
  size?: 'large'
}

interface Props extends StyleProps, BoxProps {}

const Hero = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<StyleProps>(({ size, theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  textAlign: 'center',
  paddingTop: theme.headerHeight,
  ['&, .fixed-height']: {
    minHeight: '440px',
  },
  ...(size === 'large' && {
    ['&, .fixed-height']: {
      minHeight: '600px',
    },
  }),
}))

const FixedHeightHero = ({ ...props }: Props) => {
  return <Hero {...props} />
}

export default memo(FixedHeightHero)
