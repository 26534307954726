// npm
import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'

// root
import { pageContextProps } from '../../../gatsby-node'

// components
import ArtistGrid from '@organisms/grid/ArtistGrid'
import LandingLayout from './LandingLayout'

type artistNode = {
  node: Queries.DatoCmsArtist
}

interface Props {
  artists: artistNode[]
  categoryData: Queries.DatoCmsArtworkCategory
  pageContext: PageProps & pageContextProps
}

const ArtistLandingLayout = ({ artists, categoryData, pageContext }: Props) => {
  const data = useStaticQuery<Queries.ArtistLandingLayoutQuery>(graphql`
    query ArtistLandingLayout {
      datoCmsLanding(originalId: { eq: "63984039" }) {
        ...landing
      }
      allArtists: allDatoCmsArtist {
        edges {
          node {
            category {
              originalId
            }
          }
        }
      }
    }
  `)

  return (
    <LandingLayout
      type="artist"
      pageContext={pageContext}
      allItems={data.allArtists.edges}
      landingData={data.datoCmsLanding}
      categoryLandingData={categoryData}
    >
      <ArtistGrid artists={artists} />
    </LandingLayout>
  )
}

export default ArtistLandingLayout
