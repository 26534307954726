// npm
import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { Box, Container, Stack, useTheme } from '@mui/material'

// root
import { pageContextProps } from '../../../gatsby-node'

// components
import SmallDivider from '@atoms/dividers/SmallDivider'
import FixedHeightHero from '@atoms/layout/FixedHeightHero'
import FilterBar from '@molecules/filters/FilterBar'
import Layout from '@organisms/global/Layout'

type artworkNode = {
  node: Queries.DatoCmsArtwork
}
type artistNode = {
  node: Queries.DatoCmsArtist
}

interface Props {
  type: 'artist' | 'artwork'
  allItems: artworkNode[] | artistNode[]
  children: React.ReactNode
  landingData: Queries.DatoCmsLanding
  categoryLandingData?: Queries.DatoCmsArtworkCategory
  pageContext: PageProps & pageContextProps
}

const LandingLayout = ({
  type,
  allItems,
  children,
  landingData,
  categoryLandingData,
  pageContext,
}: Props) => {
  const theme = useTheme()

  const data = useStaticQuery<Queries.LandingLayoutQuery>(graphql`
    query LandingLayout {
      allDatoCmsArtworkCategory(sort: { fields: position }) {
        edges {
          node {
            slug
            name
            originalId
            model {
              apiKey
            }
          }
        }
      }
    }
  `)
  const categories = data.allDatoCmsArtworkCategory.edges
  let activeCategories: Queries.DatoCmsArtworkCategory[] = []
  categories.map((item: any) => {
    const category: Queries.DatoCmsArtworkCategory = item.node
    const filtered = allItems.filter((filterItem: any) => {
      if (type === 'artist') {
        const artist = filterItem.node as Queries.DatoCmsArtist
        return artist.category.some(
          (artistCategory) => artistCategory.originalId === category.originalId
        )
      }

      if (type === 'artwork') {
        const artwork = filterItem.node as Queries.DatoCmsArtwork
        return artwork.category.originalId === category.originalId
      }
    })
    if (filtered.length > 0) {
      activeCategories.push(category)
    }
  })

  let seoTags = {
    seoTags: landingData.seoMetaTags,
  }
  if (categoryLandingData) {
    seoTags = {
      ...seoTags,
      titlePrefix: categoryLandingData.name,
      customDescription: categoryLandingData[`${type}Text`],
    }
  }
  return (
    <Layout activeNavSlug={pageContext.activeNavSlug} metadata={seoTags}>
      <FixedHeightHero>
        <Stack justifyContent="center" className="fixed-height">
          <Container maxWidth="md" sx={{ mt: 4 }}>
            {categoryLandingData && (
              <>
                <Box
                  typography="smallHeading"
                  fontFamily={theme.typography.fontFamilySerif}
                >
                  {landingData.pageTitle}
                </Box>
                <SmallDivider sx={{ m: `${theme.spacing(2)} auto` }} />
              </>
            )}

            <Box component="h1" typography="h1" m={0}>
              {categoryLandingData
                ? categoryLandingData.name
                : landingData.pageTitle}
            </Box>
            <Box
              sx={{
                fontSize: {
                  xs: '1.8rem',
                  sm: '1.85rem',
                  md: '1.9rem',
                  lg: '1.95rem',
                  xl: '2rem',
                },
                fontWeight: theme.typography.fontWeightLight,
                mt: 2,
                mb: 4,
              }}
            >
              {categoryLandingData
                ? categoryLandingData[`${type}Text`]
                : landingData.introText}
            </Box>
            <SmallDivider sx={{ margin: '0 auto' }} />
          </Container>
        </Stack>
      </FixedHeightHero>
      <FilterBar
        allLabel={type === 'artist' ? 'Artists' : 'Artworks'}
        activeFilters={activeCategories}
        categoryPageId={pageContext.categoryPageId}
        categoryPageSlug={pageContext.categoryPageSlug}
        pathPrefix={pageContext.activeNavSlug}
      />
      <Box component="section" bgcolor="primary.light" py={6}>
        <Container maxWidth="xl">{children}</Container>
      </Box>
    </Layout>
  )
}

export default LandingLayout
