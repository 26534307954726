// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

import ArtistLandingLayout from '@templates/layouts/ArtistLandingLayout'

// components
interface Props {
  data: Queries.ArtistLandingQuery
  pageContext: PageProps & pageContextProps
}

const ArtistLanding = ({ data, pageContext }: Props) => {
  return (
    <ArtistLandingLayout
      artists={data.allDatoCmsArtist.edges}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query ArtistLanding {
    allDatoCmsArtist(
      filter: { featuredArtist: { eq: true } }
      sort: { fields: surname, order: ASC }
    ) {
      edges {
        node {
          ...artistBase
        }
      }
    }
  }
`

export default ArtistLanding
