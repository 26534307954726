// npm
import React, { memo } from 'react'
import { Box, Container, styled } from '@mui/material'
import { SwiperSlide } from 'swiper/react'

// components
import Divider from '@atoms/dividers/Divider'
import InternalLink from '@atoms/links/InternalLink'
import FreeHoriztonalSwiper from '@molecules/swipers/FreeHoriztonalSwiper'

interface Props {
  allLabel: 'Artists' | 'Artworks'
  activeFilters: Queries.DatoCmsArtworkCategory[]
  categoryPageId?: string
  pathPrefix: string
}

interface StyledInternalLinkProps {
  active?: boolean
}

const StyledSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
  ['&:not(:last-of-type)']: {
    ['.swiper-item']: {
      paddingRight: '43px',
      ['&:after']: {
        content: '""',
        display: 'block',
        width: '3px',
        height: '3px',
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
      },
    },
  },
}))

const ListItem = styled(Box)(({ theme }) => ({
  listStyle: 'none',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: '40px',
  paddingRight: '40px',
  fontFamily: theme.typography.fontFamilySerif,
  textTransform: 'uppercase',
  letterSpacing: '0.3rem',
  position: 'relative',
}))

const StyledInternalLink = styled(InternalLink, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledInternalLinkProps>(({ active, theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  position: 'relative',
  ['&:after']: {
    content: '""',
    display: 'block',
    width: '20px',
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translateX(-50%)',
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
    ...(active && {
      opacity: 1,
    }),
  },
}))

const FilterBar = ({
  allLabel,
  activeFilters,
  categoryPageId,
  pathPrefix,
}: Props) => {
  let initialSlide: number = 0
  {
    activeFilters.map(
      (filter: Queries.DatoCmsArtworkCategory, index: number) => {
        if (categoryPageId === filter.originalId) {
          initialSlide = index
        }
      }
    )
  }

  return (
    <Box bgcolor="secondary.main" color="common.white">
      <Container maxWidth="xl">
        <Divider colorVariant="light-trans" />
      </Container>
      <FreeHoriztonalSwiper initialSlide={initialSlide}>
        <StyledSwiperSlide>
          <ListItem className="swiper-item">
            <StyledInternalLink
              slug={pathPrefix}
              active={categoryPageId ? false : true}
            >
              All {allLabel}
            </StyledInternalLink>
          </ListItem>
        </StyledSwiperSlide>
        {activeFilters &&
          Array.isArray(activeFilters) &&
          activeFilters.length > 0 && (
            <>
              {activeFilters.map((filter: Queries.DatoCmsArtworkCategory) => {
                return (
                  <StyledSwiperSlide key={`cat-${filter.originalId}`}>
                    <ListItem className="swiper-item">
                      <StyledInternalLink
                        slug={`${pathPrefix}/${filter.slug}`}
                        active={categoryPageId === filter.originalId}
                      >
                        {filter.name}
                      </StyledInternalLink>
                    </ListItem>
                  </StyledSwiperSlide>
                )
              })}
            </>
          )}
      </FreeHoriztonalSwiper>
    </Box>
  )
}

export default memo(FilterBar)
