// npm
import React, { memo } from 'react'
import { Box, styled } from '@mui/material'
import { Swiper } from 'swiper/react'
import SwiperCore, { FreeMode } from 'swiper'
import 'swiper/css'

export interface Props {
  initialSlide?: number
  children: React.ReactNode
}

const SwiperWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  ['.swiper']: {
    alignItems: 'stretch',
  },
  ['.swiper-slide']: {
    width: 'auto',
  },
}))

const FreeHorizontalSwiper = ({ children, initialSlide }: Props) => {
  SwiperCore.use([FreeMode])

  return (
    <SwiperWrapper>
      <Swiper
        slidesPerView="auto"
        spaceBetween={0}
        freeMode={true}
        className="swiper-horizontal-free"
        initialSlide={initialSlide ? initialSlide : 0}
      >
        {children}
      </Swiper>
    </SwiperWrapper>
  )
}

export default memo(FreeHorizontalSwiper)
