// npm
import React from 'react'
import { Box, alpha, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import InternalLink from '@atoms/links/InternalLink'

// helpers
import artistName from '@helpers/artistName'

type artistNode = {
  node: Queries.DatoCmsArtist
}

interface Props {
  artists: artistNode[]
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}))

const BorderGridItem = styled(Grid)(({ theme }) => ({
  height: '100% !important',
  [theme.breakpoints.up('sm')]: {
    ['&:nth-of-type(odd)']: {
      borderRight: `1px solid ${theme.palette.outlineDarkTransLight}`,
    },
    [theme.breakpoints.up('lg')]: {
      ['&:nth-of-type(even)']: {
        borderRight: `1px solid ${theme.palette.outlineDarkTransLight}`,
      },
    },
  },
}))

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

const ArtistLink = styled(InternalLink)(({ theme }) => ({
  fontSize: '2.8rem',
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightThin,
  letterSpacing: '0.1rem',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  textDecorationColor: alpha(theme.palette.primary.main, 0),
  textUnderlineOffset: '1rem',
  textDecorationThickness: '1px',
  transition: theme.transitions.create(['color', 'text-decoration-color']),
  ['&:hover']: {
    color: theme.palette.text.primary,
    textDecorationColor: theme.palette.primary.main,
  },
}))

const ArtistGrid = ({ artists }: Props) => {
  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate3D(-50%,-50%,0)',
          margin: '0 !important',
        }}
      >
        <BorderGridItem xs={12} sm={6} lg={4} />
        <BorderGridItem xs={12} sm={6} lg={4} />
      </Grid>

      <Box
        sx={{
          position: 'relative',
          columnCount: { xs: 1, sm: 2, lg: 3 },
          textAlign: 'center',
        }}
      >
        {artists.map((item: artistNode, index: number) => {
          const artist: Queries.DatoCmsArtist = item.node
          return (
            <Box key={artist.originalId} p={1}>
              <ArtistLink slug={artist.slug} apiKey={artist.model.apiKey}>
                {artistName({ artist: artist })}
              </ArtistLink>
            </Box>
          )
        })}
      </Box>
    </Wrapper>
  )
}

export default ArtistGrid
